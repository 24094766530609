import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';

ReactDOM.render(
  <React.StrictMode>
     <AuthContextProvider>
        <BrowserRouter>
          <App />
      </BrowserRouter>
      </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

